h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

a,
button,
div,
li,
p,
span {
	font-family: 'Noto Sans TC', 'Helvetica Neue', 'Helvetica', 'Arial',
		sans-serif;
}
